import {deepMerge, getHostname, isDemoHostname, isIdAnItem} from '@/common/utils/helper';
import {ConfiguratorSettings, UiInitData} from '@/configurator/embedding/types';
import {HANDSHAKE_MESSAGES} from '@/configurator/embedding/utils';
import RapiAccess from '@roomle/web-sdk/lib/definitions/common-core/src/rapi-access';
import {Nullable} from '@/common/utils/types';
import {getLanguage} from '@/common/utils/browser';
// tslint:disable:no-unused-expression -- only import type because otherwise everything is bundled
import type {MessageHandler} from '@/configurator/embedding/message-handler';
import {RoomleSdkWrapper} from '@/configurator/business-logic/roomle-sdk-wrapper';

export const CONFIGURATOR_IDLE = '(idle)';

// not supporting IE11: https://caniuse.com/#feat=urlsearchparams
export const getInitData = (useFallbackId: boolean = true): UiInitData => {
  const urlParams = new URLSearchParams(window.location.search);
  // cast to any because it comes from URL params after this method initData
  // has the correct shape (types, names etc)
  const initData: any = {};
  urlParams.forEach((value, key) => initData[key] = value);
  castInitData(initData);
  if (!useFallbackId) {
    return initData as UiInitData;
  }
  if (!initData.id) {
    initData.id = 'usm:frame';
  }
  if (initData.isItem === undefined) {
    initData.isItem = isIdAnItem(initData.id);
  }
  return initData as UiInitData;
};

export const castAndFixInitData = (initData: UiInitData): UiInitData => {
  castInitData(initData);
  if (initData?.customApiUrl) {
    initData.customApiUrl = decodeURIComponent(initData.customApiUrl);
  }
  if (initData.emails === undefined) {
    initData.emails = false;
  }
  if (initData.shareUrl) {
    initData.deeplink = initData.shareUrl.replace(LEGACY_SHARE_PLACEHOLDER, SHARE_PLACEHOLDER);
  }
  return initData;
};

export const castInitData = (obj: Nullable<{[key: string]: any}>): void => {
  if (!obj) {
    return;
  }
  const keys = Object.keys(obj);
  for (const key of keys) {
    const value = obj[key];
    // need to type-check for null because typeof null evaluates to object
    // see here why this is like it is: https://2ality.com/2013/10/typeof-null.html
    if (!Array.isArray(value) && typeof value === 'object' && value !== null) {
      return castInitData(value);
    }
    if (Array.isArray(value)) {
      for (const entry of value) {
        castInitData(entry);
      }
      return;
    }

    if (value === 'true' || value === 'false') {
      obj[key] = value === 'true';
    }
  }
};

export const mergeInitData = (configuratorSettings: ConfiguratorSettings | {id: string; settings: UiInitData}, currentInitData: UiInitData): UiInitData => {
  currentInitData.configuratorId = configuratorSettings.id;
  const remoteInitData = configuratorSettings.settings || {};
  // This is a performance optimization so we do not need to fetch
  // configurator settings twice
  if (!currentInitData.overrideTenant && (configuratorSettings as ConfiguratorSettings).tenant) {
    // use as any because we send tenant id as string but SDK requires to send a number
    // casting to number could become a problem when we change tenant IDs to something
    // random instead of a integer which is incremented
    (currentInitData as any).overrideTenant = (configuratorSettings as ConfiguratorSettings).tenant;
  }
  return deepMerge(remoteInitData, currentInitData);
};

export const getEmbeddingInitData = async (messageHandler: MessageHandler): Promise<UiInitData> => {
  const initData = await messageHandler.sendMessage(HANDSHAKE_MESSAGES.REQUEST_BOOT);
  if (initData.id && initData.isItem === undefined) {
    initData.isItem = isIdAnItem(initData.id);
  }
  // @schobi check where we can set global-init-data so that it's also available for getConfiguratorSettings
  // sdkWrapper.setGlobalInitData(initData);
  // This code sets tenant correctly also for old embedding.js lib versions
  // if (!initData.overrideTenant && initData.configuratorId) {
  //   const {tenant} = await rapiAccess.getConfiguratorSettings(initData.configuratorId) as ConfiguratorSettings;
  //   if (tenant) {
  //     initData.overrideTenant = tenant;
  //   }
  // }
  return castAndFixInitData(initData);
};

export const getRemoteInitData = async (rapiAccess: RapiAccess, configuratorId: string): Promise<UiInitData> => {
  const {id, tenant, settings = {}} = await rapiAccess.getConfiguratorSettings(configuratorId) as ConfiguratorSettings;
  const remoteInitData = settings as UiInitData;
  remoteInitData.configuratorId = id;
  if (!remoteInitData.overrideTenant && tenant) {
    // use as any because we send tenant id as string but SDK requiers to send a number
    // casting to number could become a problem when we change tenant IDs to something
    // random instead of a integer which is incremented
    remoteInitData.overrideTenant = tenant;
  }
  return castAndFixInitData(remoteInitData);
};

export const getFallbackInitData = () => {
  const fallbackInitData = {} as UiInitData;
  if (!fallbackInitData.locale) {
    fallbackInitData.locale = getLanguage();
  }
  if (fallbackInitData.id === CONFIGURATOR_IDLE) {
    delete fallbackInitData.id;
  }

  const hostname = getHostname();
  if (hostname && isDemoHostname(hostname)) {
    fallbackInitData.configuratorId = 'demoConfigurator';
  }

  fallbackInitData.customApiUrl = process.env.VUE_APP_RAPI_URL as string;
  fallbackInitData.emails = false;
  return fallbackInitData as UiInitData;
};

export const LEGACY_SHARE_PLACEHOLDER = '<CONF_ID>';
export const SHARE_PLACEHOLDER = '#CONFIGURATIONID#';

/**
 * combines init data in this order: query params > embedding > remote > fallback
 *
 * some exceptions to consider:
 * - tenant: when do we need the tenant and where do we get it from?
 *
 * @param messageHandler
 * @param sdkWrapper
 * @param fallbackInitData
 */
export const combineInitData = async (messageHandler: Nullable<MessageHandler>, sdkWrapper: RoomleSdkWrapper, queryParamsInitData: UiInitData): Promise<UiInitData> => {
  castAndFixInitData(queryParamsInitData);
  const fallbackInitData = getFallbackInitData();

  let embeddingInitData = null;
  if (messageHandler) {
    embeddingInitData = await getEmbeddingInitData(messageHandler);
  }

  sdkWrapper.setGlobalInitData(deepMerge(deepMerge(fallbackInitData, embeddingInitData), queryParamsInitData));
  const rapiAccess = await sdkWrapper.getRapiAccess();

  const configuratorId = queryParamsInitData.configuratorId || embeddingInitData?.configuratorId || fallbackInitData.configuratorId;
  if (!configuratorId) {
    throw new Error('Please provide a correct configuratorId, you get the correct ID from your Roomle Contact Person');
  }
  const remoteInitData = await getRemoteInitData(rapiAccess, configuratorId!);

  const finalInitData = deepMerge(deepMerge(deepMerge(fallbackInitData, remoteInitData), embeddingInitData), queryParamsInitData);
  sdkWrapper.setGlobalInitData(finalInitData);
  return finalInitData;
};
