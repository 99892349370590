// for details see: https://stackoverflow.com/a/9733420/10800831

import {Nullable} from '@/common/utils/types';
import {UiInitData} from '@/configurator/embedding/types';

interface NumericRgb {
  r: number;
  g: number;
  b: number;
}

const hex2Rgb = (hex: string): Nullable<NumericRgb> => {
  if (typeof hex === 'string' && hex.length === 4) {
    // handle RGB short version, for details see:
    // https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#RGB_colors
    hex = [...hex].map((value) => value + value).join('').replace('##', '#');
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
};

const luminanace = ({r, g, b}: NumericRgb): number => {
  const a = [r, g, b].map((value) => {
    value = value / 255;
    return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const contrast = (rgb1: NumericRgb, rgb2: NumericRgb): number => {
  const lum1 = luminanace(rgb1);
  const lum2 = luminanace(rgb2);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

const getHigherContrastColor = (color: string, possiblity1: string, possiblity2: string, debug: string) => {
  const primaryColor = hex2Rgb(color);
  if (!primaryColor) {
    console.warn('skin[' + debug + '-color] seems to be not a hex string either provide a value for skin[color-on-' + debug + '] or use a hex value, e.g.: #000000');
    return null;
  }
  const possibleError = 'can not calculate contrast for default color with "';
  const black = hex2Rgb(possiblity1);
  if (!black) {
    console.warn(possibleError + possiblity1 + '"');
    return null;
  }
  const white = hex2Rgb(possiblity2);
  if (!white) {
    console.warn(possibleError + possiblity2 + '"');
    return null;
  }

  const contrastWithBlack = contrast(primaryColor, black!);
  const contrastWithWhite = contrast(primaryColor, white!);
  return (contrastWithBlack > contrastWithWhite) ? possiblity1 : possiblity2;
};

export const prepareSkin = (initData: UiInitData, blackText: string, whiteText: string) => {
  if (initData.skin?.['primary-color']) {
    const rgbValues = hex2Rgb(initData.skin['primary-color']);
    if (rgbValues) {
      const {r, g, b} = rgbValues;
      initData.skin['primary-color-in-rgb'] = r + ',' + g + ',' + b;
    }
  }
  if (initData.skin?.['primary-color'] && !initData.skin?.['cta-color']) {
    initData.skin['cta-color'] = initData.skin['primary-color'];
  }

  if (initData.skin?.['primary-color'] && !initData.skin?.['color-on-primary']) {
    const color = getHigherContrastColor(initData.skin['primary-color'], blackText, whiteText, 'primary');
    if (color) {
      initData.skin['color-on-primary'] = color;
    }
  }

  if (initData.skin?.['cta-color'] && !initData.skin?.['color-on-cta']) {
    const color = getHigherContrastColor(initData.skin['cta-color'], blackText, whiteText, 'cta');
    if (color) {
      initData.skin['color-on-cta'] = color;
    }
  }

  if (!initData.skin) {
    initData.skin = {};
  }
};
