import {Nullable} from '@/common/utils/types';
import {isInIframe} from '@/common/utils/iframe';
import {RapiConfiguration, RapiItem, RapiTenant} from '@roomle/web-sdk/lib/definitions/typings/rapi-types';
import {SHARE_PLACEHOLDER} from '@/common/utils/init-data';

export const throttle = <T extends (...args: any[]) => any>(func: T, delay: number = 16): ((...funcArgs: Parameters<T>) => void) => {
  let time: number;
  let called = false;
  let skippedCalls = 0;
  let trailingCall: Nullable<any> = null;
  return function (this: T, ..._args: Parameters<T>): void {
    if (!time) {
      time = Date.now();
    }
    if (!called || (time + delay - Date.now()) < 0) {
      func.apply(this, [...arguments]);
      time = Date.now();
      called = true;
    } else {
      skippedCalls++;
    }
    if (!trailingCall) {
      trailingCall = setInterval(() => {
        if (skippedCalls) {
          func.apply(this, [...arguments]);
        }
        skippedCalls = 0;
        clearInterval(trailingCall);
        trailingCall = null;
      }, delay);
    }
  };
};

export const round = (float: number, precision: number) => {
  if (precision === -1) {
    return float;
  }
  const factor = Math.pow(10, precision);
  return Math.round(float * factor) / factor;
};

export const isIdAnItem = (id: string) => id.split(':').length - 1 <= 1;

/**
 * Get cloudinary (cdn) url for given parameters
 * @param url
 * @param format
 * @param size
 * @param step
 */
export const getImageURL = (url: string, size = 32, step = 32): string => {
  // more info about valid transformation parameters -> https://cloudinary.com/documentation/image_transformations
  return 'https://res.cloudinary.com/roomle/image/fetch/w_auto:' + step + ',w_' + size + ',h_' + size + ',f_auto,dpr_auto/' + encodeURIComponent(url);
};

export interface KeyValuePair {
  key: string;
  value: string;
}

export const addMetaHeaderTag = (id: string, attributes: KeyValuePair[]): void => {
  if (!attributes || document.head.querySelector('#' + id)) {
    return;
  }
  const htmlMetaElement = document.createElement('meta');
  htmlMetaElement.setAttribute('id', id);
  attributes.forEach((attribute) => {
    htmlMetaElement.setAttribute(attribute.key, attribute.value);
  });
  document.head.appendChild(htmlMetaElement);
};

export const uuid = (a: Nullable<string> = null): string => {
  // tslint:disable no-bitwise
  return a ? (parseInt(a, 10) ^ Math.random() * 16 >> parseInt(a, 10) / 4).toString(16) :
    ((1e7).toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
  // tslint:enable no-bitwise
};

export const snakeCase = (str: string) => str.replace(/[\w]([A-Z])/g, (m) => m[0] + '-' + m[1]).toLowerCase();

export interface KernelBoundsFormatted {
  width: string;
  height: string;
  depth: string;
}

export const deepCopy = <T>(o: T): T => {
  return JSON.parse(JSON.stringify(o));
};

export const toBoolean = (value: boolean | undefined, defaultValue: boolean = true): boolean => {
  return value === undefined ? defaultValue : value;
};

export const wait = (delayInMs: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, delayInMs));
};

/**
 * Recursively merge properties of two objects.
 * If a property exists in both it, property of obj2 is used
 * @param obj1
 * @param obj2
 */
export const deepMerge = (obj1: any, obj2: any): any => {
  // tslint:disable-next-line
  for (const p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor === Object) {
        obj1[p] = deepMerge(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];
    }
  }
  return obj1;
};

export const NAMES_FOR_LOCALHOST = [
  '127.0.0.1',
  'localhost',
  '0.0.0.0',
];

export const getHostname = (): string | null => {
  const isIframe = isInIframe();
  let url = window.location.href;
  if (isIframe) {
    if (!document.referrer) {
      return null;
    }
    url = document.referrer;
  }

  const {hostname} = new URL(url);
  return hostname;
};

export const isDemoHostname = (hostname: string): boolean => {
  if (NAMES_FOR_LOCALHOST.includes(hostname)) {
    return true;
  }

  if (hostname.endsWith('roomle.com')) {
    return true;
  }

  // exception for CI builds
  if (hostname.endsWith('gitlab.io') || hostname.endsWith('gitlab.com')) {
    return true;
  }

  return false;
};

export const scriptInfo = (...args: any[]) => {
  console.info('Script Info:', ...args);
};

export const getGlbAssetUrl = (obj: RapiItem | RapiConfiguration): Nullable<string> => {
  if ((obj as any).assets?.glTF?.url) {
    return (obj as any).assets.glTF.url;
  }
  if ((obj as any).assets?.glb?.url) {
    return (obj as any).assets.glb.url;
  }
  return null;
};

export const getUsdzAssetUrl = (obj: RapiItem | RapiConfiguration): Nullable<string> => {
  if ((obj as any).assets?.usdz?.url) {
    return (obj as any).assets.usdz.url;
  }
  return null;
};

export const hasArAssets = (item: RapiItem): boolean => {
  return getGlbAssetUrl(item) !== null && getUsdzAssetUrl(item) !== null;
};

export const formatPrice = (currencySymbol: Nullable<string>, price: Nullable<number>): Nullable<string> => {
  if (typeof price !== 'number') {
    return null;
  }
  const prefix = (currencySymbol) ? currencySymbol + ' ' : '';
  return prefix + price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
// we choose to round to 4 decimals because basically we do not know
// where to round... but we assume that we do not have decimals with
// more than 4 decimals on purpose
const ROUNDING_DECIMALS = 4;
export const normalizeFloatingNumber = (num: number, decimals: number = ROUNDING_DECIMALS): number => {

  if (num % 1 === 0) {
    return num;
  }

  const factor = (decimals !== ROUNDING_DECIMALS) ? Math.pow(10, decimals) : 10000;
  return Math.round((num + Number.EPSILON) * factor) / factor;
};

export const checkForActivePackage = (tenant: RapiTenant): boolean => {
  if (!tenant.packages || !tenant.packages.length) {
    return false;
  }
  /* as any because SDK does not type correctly */
  return !!tenant.packages.find((activePackage: any) => activePackage.package !== 'tenant_free');
};

export const oneTimeInterval = (callback: () => void, delay: number) => {
  const intervalId = window.setInterval(() => {
    callback();
    clearInterval(intervalId);
  }, delay);
  return intervalId;
};

// this is basically to make tests working because in all other situations we have a polyfill
export const runOnIdle = !(window as any).requestIdleCallback ? (callback: () => Promise<void>) => callback() : (window as any).requestIdleCallback;

export const createShareLinkFromUrl = (url: string) => {
  let actualUrl = url;
  const separator = actualUrl.includes('?') ? '&' : '?';
  const idQueryParam = 'id=';
  const idQueryParamWithPlaceholder = idQueryParam + SHARE_PLACEHOLDER;
  const hasId = actualUrl.includes(idQueryParam);
  if (!hasId) {
    actualUrl += separator + idQueryParamWithPlaceholder;
  } else {
    actualUrl = actualUrl.replace(new RegExp(idQueryParam + '[a-zA-Z0-9:_-]+'), idQueryParamWithPlaceholder);
  }
  return actualUrl;
};

export const isLocationJestTest = () => location.href === 'http://localhost/';

// Merge a `source` object to a `target` recursively
export const objectMerge = (target: object, source: object) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    // @ts-ignore
    if (source[key] instanceof Object) {
      // @ts-ignore
      Object.assign(source[key], objectMerge(target[key], source[key]));
    }
  }

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};

export const getParentUrl = (storeParentUrl: string) => {
  const inIframe = (parent !== window);
  let parentUrl = null;

  if (inIframe) {
    if (storeParentUrl) {
      parentUrl = new URL(storeParentUrl);
    } else {
      parentUrl = new URL(document.referrer);
    }
  }

  return parentUrl;
};
