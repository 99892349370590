import {TranslationSource} from '@/common/plugins/localize';
import {deepMerge} from '@/common/utils/helper';

export default class LocalTranslationSource implements TranslationSource {

  private _locale: string;
  private _overrideTranslation: any;

  constructor(locale: string, overrideTranslation?: any) {
    this._locale = locale;
    if (overrideTranslation) {
      this._overrideTranslation = overrideTranslation[this._locale];
    }
  }

  public async getTranslations(): Promise<any> {
    try {
      const response = await fetch('./translations/' + this._locale + '.json');
      const json = await response.json();
      if (this._overrideTranslation) {
        deepMerge(json, this._overrideTranslation);
      }
      return json;
    } catch (e) {
      console.error('Did not find language ' + this._locale + ', fallback to english');
      return this._getFallbackTranslations();
    }
  }

  private async _getFallbackTranslations(): Promise<any> {
    try {
      const response = await fetch('./translations/en.json');
      const json = await response.json();
      if (this._overrideTranslation) {
        deepMerge(json, this._overrideTranslation);
      }
      return json;
    } catch (e) {
      console.error(e);
      return {};
    }
  }

}
