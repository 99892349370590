import {Nullable} from '@/common/utils/types';

// see why: https://stackoverflow.com/a/58065241/10800831
//          https://stackoverflow.com/a/58064750/10800831
export const isIOs = () => (/iPad|iPhone|iPod/i.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;

export const hasARQuicklook = (): Nullable<boolean> => {
  if (isAndroid()) {
    return null; // currently there is no way to detect AR Quicklook on Android
  }
  if (isIOs()) {
    try {
      // for details see Safari Docs:
      // https://webkit.org/blog/8421/viewing-augmented-reality-assets-in-safari-for-ios/
      const a = document.createElement('a');
      return a.relList.supports('ar');
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  return null;
};

export const isInAppBrowser = (): Nullable<boolean> => {
  if (isAndroid()) {
    return null;
  }
  try {
    // for more details see: https://stackoverflow.com/a/54231915/10800831
    const standalone = (window.navigator as any).standalone;
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isSafari = /safari/.test(userAgent);
    if (isIOs()) {
      return !standalone && !isSafari;
    }
  } catch (e) {
    console.error(e);
    return null;
  }
  return null;
};

export const isAndroid = () => /(android)/i.test(navigator.userAgent);

export const deviceSupportsAR = (): boolean => {
  if (isIOs() && hasARQuicklook()) {
    return true;
  }
  return isAndroid();
};
