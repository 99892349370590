let translations: any = {};
let waitingForTranslations: Promise<object>;

const getProperty = (o: object, path: string): unknown => {
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, '');           // strip a leading dot
  const a = path.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = (o as any)[k];
    } else {
      return;
    }
  }
  return o;
};

const Localize: any = {
  install(Vue: any, options: LocalizeOptions) {
    if (options.translationSource) {
      waitingForTranslations = new Promise<object>(((resolve) => {
        options.translationSource.getTranslations().then((receivedTranslations: any) => {
          translations = receivedTranslations;
          resolve(translations);
        });
      }));
    }
    Vue.mixin({
      data() {
        return {
          localizations: {
            t: (name: string) => {
              return name;
            },
          },
        };
      },
      computed: {
        t() {
          (this as any).$translations.then((t: any) => {
            (this as any).localizations.t = (name: string) => {
              return getProperty(t, name);
            };
          });
          return (this as any).localizations;
        },
      },
    });
    Vue.prototype.$translations = waitingForTranslations;
  },
};

export default Localize;

export interface LocalizeOptions {
  translationSource: TranslationSource;
}

export interface TranslationSource {
  getTranslations(): any;
}
