import {RoomleEmbeddingApiKeys} from '@/configurator/embedding/roomle-configurator-api';
import {ConfiguratorSettings, UiInitData} from '@/configurator/embedding/types';

export const NAMESPACE: {[key: string]: keyof RoomleEmbeddingApiKeys | 'callbacks'} = {
  SDK: 'extended',
  CALLBACKS: 'callbacks',
  UI: 'ui',
  ANALYTICS: 'analytics',
};

export const NAMESPACE_SEPARATOR = '.';
export const SDK_CALLBACK = NAMESPACE.SDK + NAMESPACE_SEPARATOR + NAMESPACE.CALLBACKS;

export const HANDSHAKE_MESSAGES = {
  REQUEST_BOOT: 'requestBoot',
  SETUP: 'setup',
  WEBSITE_READY: 'websiteReady',
};

const HIDDEN_METHODS = ['constructor', 'callbacks'];
export const isExposable = (name: string) => name[0] !== '_' && !HIDDEN_METHODS.includes(name);

export const getMethodNames = (proto: any, namespace: string, isCallback: boolean = false): string[] => {
  if (!proto) {
    return [];
  }
  const allMethods = Object.getOwnPropertyNames(proto);
  const subNamespace = isCallback ? NAMESPACE.CALLBACKS + NAMESPACE_SEPARATOR : '';
  return allMethods.filter(isExposable).map((name) => namespace + NAMESPACE_SEPARATOR + subNamespace + name);
};

export const getConfiguratorSettings = async (configuratorId: string, initData: UiInitData): Promise<ConfiguratorSettings> => {
  if (typeof configuratorId !== 'string') {
    throw new Error('Configurator ID is not a string type: "' + (typeof configuratorId) + '"');
  }
  const server = initData.customApiUrl ? initData.customApiUrl : 'https://api.roomle.com/v2';
  const currentTenant = initData.overrideTenant || 9;
  const url = server + '/configurators/' + configuratorId;
  const apiKey = 'roomle_portal_v2';
  const token = '03-' + window.btoa((new Date()).toISOString() + ';anonymous;' + apiKey);
  const createHeaders = () => {
    const headers: any = {
      apiKey,
      currentTenant,
      locale: 'en',
      language: 'en',
      device: 1,
      token,
      platform: 'web',
    };
    return new Headers(headers);
  };

  const request = new Request(url, {
    method: 'GET',
    headers: createHeaders(),
    mode: 'cors',
    cache: 'default',
  });
  const response = await fetch(request);
  const {configurator} = await response.json();
  return configurator;
};
