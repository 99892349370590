import RoomleSdk from '@roomle/web-sdk';
import RapiAccess from '@roomle/web-sdk/lib/definitions/common-core/src/rapi-access';
import {GlbViewer} from '@roomle/web-sdk/lib/definitions/glb-viewer-core/src/glb-viewer';
import {Configurator} from '@roomle/web-sdk/lib/definitions/configurator-core/src/configurator';
import {Nullable} from '@/common/utils/types';
import {UiInitData} from '@/configurator/embedding/types';
import {GlobalInitDataDefinition} from '@roomle/web-sdk/lib/definitions/common-core/src/utils/shims';

/**
 * We use this class in a plugin so we
 * can rely on DI to change it during testing
 */
export class RoomleSdkWrapper {

  private _configuratorInstance: Nullable<Configurator> = null;
  private _viewerInstance: Nullable<GlbViewer> = null;

  public async getConfigurator(element: HTMLElement, initData?: UiInitData): Promise<Configurator> {
    if (this._configuratorInstance) {
      this._viewerInstance?.pause();
      if (!element) {
        throw new Error('Can not resume with configurator, please provide a proper HTMLElement');
      }
      this._configuratorInstance.resume(element);
      return this._configuratorInstance;
    }

    const configurator = await RoomleSdk.getConfigurator(initData);
    this._configuratorInstance = configurator;
    this._configuratorInstance.boot();
    this._viewerInstance?.pause();
    return configurator;
  }

  public async getGlbViewer(element: HTMLElement, initData?: UiInitData): Promise<GlbViewer> {
    if (this._viewerInstance) {
      this._configuratorInstance?.pause();
      if (!element) {
        throw new Error('Can not resume with viewer, please provide a proper HTMLElement');
      }
      this._viewerInstance.resume(element);
      return this._viewerInstance;
    }
    const glbViewer = await RoomleSdk.getGlbViewer(initData);
    this._viewerInstance = glbViewer;
    this._configuratorInstance?.pause();
    this._viewerInstance.boot();
    return glbViewer;
  }

  public async getRapiAccess(): Promise<RapiAccess> {
    return await RoomleSdk.getRapiAccess();
  }

  public setGlobalInitData(initData: GlobalInitDataDefinition) {
    RoomleSdk.setGlobalInitData(initData);
  }
}
