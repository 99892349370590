// @ts-ignore
import {breakPointDesktop} from '@/common/styles/Global.module.scss';
import {wait} from '@/common/utils/helper';
import {Nullable} from '@/common/utils/types';
import {isIOs} from '@/common/utils/user-agent';

const enum DIRECTION {
  VERTICAL,
  HORIZONTAL,
}

export const isScrolledToRightEnd = (element: Nullable<HTMLElement>, threshold: number = 0) => {
  if (!element) {
    return false;
  }
  const {scrollWidth, offsetWidth, scrollLeft} = element;
  return (scrollLeft + offsetWidth) >= scrollWidth - threshold;
};

const _hasOverflow = (element: HTMLElement, direction: DIRECTION) => {
  if (!element) {
    return false;
  }
  const offsetKey = (direction === DIRECTION.HORIZONTAL) ? 'offsetWidth' : 'offsetHeight';
  const scrollKey = (direction === DIRECTION.HORIZONTAL) ? 'scrollWidth' : 'scrollHeight';
  return element[offsetKey] < element[scrollKey];
};

export const hasVerticalOverflow = (element: HTMLElement) => {
  return _hasOverflow(element, DIRECTION.VERTICAL);
};

export const hasHorizontalOverflow = (element: HTMLElement) => {
  return _hasOverflow(element, DIRECTION.HORIZONTAL);
};

export const lastVisibleElement = (container: HTMLElement, threshold: number = 0) => {
  const children = container.children;
  const length = children.length;
  if (!length) {
    return null;
  }

  const containerBoundingClientRect = container.getBoundingClientRect();
  const containerWidth = containerBoundingClientRect.left + containerBoundingClientRect.width;
  let lastVisibleChild = children[length - 1];
  for (let i = 0; i < length; i++) {
    const child = children[i];
    const childClientRect = child.getBoundingClientRect();
    if (childClientRect.left + threshold > containerWidth) {
      return lastVisibleChild;
    }
    lastVisibleChild = child;
  }
  return lastVisibleChild;
};

export const cssVariableToNumber = (cssVariable: string) => parseFloat(cssVariable.replace(/(?![\.])\D/g, ''));

export const remToPixel = (remValue: string, remBase: number) => parseFloat(remValue.replace('rem', '')) * remBase;

export const percentInStringToFloat = (percentInString: string): number => parseFloat(percentInString.replace('%', '')) / 100;
export const animationDurationInMsToFloat = (animationDuration: string): number => parseFloat(animationDuration.replace('ms', ''));

export const calcIsDesktop = () => window.innerWidth >= parseInt(breakPointDesktop.replace('px', ''), 10);

const LOADING_SCREEN_CLASSES = {
  CONTAINER: 'rml-loading',
  ANIMATION_CONTAINER: 'spinner div',
  OPACITY: 'rml-loading--opacity-0',
  HIDE: 'rml-loading--hide',
  ANIMATION_1: 'double-bounce1',
  ANIMATION_2: 'double-bounce2',
  GLTF_LOADER: 'gltf-loader',
  CIRCLE_PROGRESS: 'progress-ring__circle',
};

const FADE_OUT_DELAY = 500;
const LOADING_SCREEN_CSS = {
  TRANSITION: 'opacity ease-in-out ' + FADE_OUT_DELAY + 'ms',
};

export const removeLoadingScreen = async () => {
  const loadingScreen = document.querySelector<HTMLElement>('.' + LOADING_SCREEN_CLASSES.CONTAINER);
  if (loadingScreen) {
    loadingScreen.style.transition = LOADING_SCREEN_CSS.TRANSITION;
    await wait(0); // wait for next runloop;
    if (!loadingScreen) {
      return;
    }
    loadingScreen.classList.add(LOADING_SCREEN_CLASSES.OPACITY);
    await wait(FADE_OUT_DELAY); // wait for animation to finish;
    if (!loadingScreen) {
      return;
    }
    loadingScreen.classList.add(LOADING_SCREEN_CLASSES.HIDE);
    // Remove animation because otherwise it just continues to render in background
    const animations = loadingScreen.querySelectorAll<HTMLElement>('.' + LOADING_SCREEN_CLASSES.ANIMATION_CONTAINER);
    animations[0].classList.remove(LOADING_SCREEN_CLASSES.ANIMATION_1);
    animations[1].classList.remove(LOADING_SCREEN_CLASSES.ANIMATION_2);
  }
};

export const showLoadingScreen = async () => {
  const loadingScreen = document.querySelector<HTMLElement>('.' + LOADING_SCREEN_CLASSES.CONTAINER);
  if (loadingScreen) {
    loadingScreen.style.transition = LOADING_SCREEN_CSS.TRANSITION;
    await wait(0); // wait for next runloop;
    loadingScreen.classList.remove(LOADING_SCREEN_CLASSES.HIDE);
    loadingScreen.classList.remove(LOADING_SCREEN_CLASSES.OPACITY);
    const animations = loadingScreen.querySelectorAll<HTMLElement>('.' + LOADING_SCREEN_CLASSES.ANIMATION_CONTAINER);
    animations[0].classList.add(LOADING_SCREEN_CLASSES.ANIMATION_1);
    animations[1].classList.add(LOADING_SCREEN_CLASSES.ANIMATION_2);
  }
};

export const copyToClipboard = (text: string) => {
  const input = document.createElement('input');
  const inputId = 'hidden-input-for-clipboard';
  input.id = inputId;
  input.value = text;
  document.body.appendChild(input);
  // lifted from https://stackoverflow.com/a/43001673/1280401
  if (isIOs()) {
    const editable = input.contentEditable;
    const readOnly = input.readOnly;

    input.contentEditable = 'true';
    input.readOnly = false;

    const range = document.createRange();
    range.selectNodeContents(input);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    input.setSelectionRange(0, 999999);
    input.contentEditable = editable;
    input.readOnly = readOnly;
  } else {
    input.select();
  }
  try {
    document.execCommand('copy');
  } catch (e) {
    console.error('document.execCommand(\'copy\') failed "' + e + '"');
  }

  document.body.removeChild(input);
};

export const setLoadingProgress = (percent: number) => {
  // inspired by: https://css-tricks.com/building-progress-ring-quickly/
  const loadingContainer = document.querySelector<HTMLElement>('.' + LOADING_SCREEN_CLASSES.CONTAINER + ' .' + LOADING_SCREEN_CLASSES.GLTF_LOADER)!;
  const circle = document.querySelector<SVGCircleElement>('.' + LOADING_SCREEN_CLASSES.CIRCLE_PROGRESS)!;
  const radius = circle.r.baseVal.value;
  const circumference = radius * 2 * Math.PI;

  if (loadingContainer.classList.contains(LOADING_SCREEN_CLASSES.HIDE)) {
    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = `${circumference}`;
    loadingContainer.classList.remove(LOADING_SCREEN_CLASSES.HIDE);
  }

  const offset = circumference - percent * circumference;
  circle.style.strokeDashoffset = Math.round(offset).toString();

};
