export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
export const useEmbeddingApi = () => {
  if (window.location.href.includes('api=false')) {
    return false;
  }
  return true;
};

export const sameOrigin = () => {
  try {
    return !!window.parent.document;
  } catch (e) {
    return false;
  }
};
