import {Nullable} from '@/common/utils/types';

export const BROWSER_LANGUAGE_PROPERTY_KEYS_KNOWN = ['language', 'browserLanguage', 'userLanguage', 'systemLanguage'];

export const getLanguage = (lang: Nullable<string> = null) => {
  const navigator = window.navigator;

  if (lang) {
    return lang.substr(0, 2);
  }

  if (Array.isArray(navigator.languages) && navigator.languages.length > 0) {
    return navigator.languages[0].substr(0, 2);
  }

  for (let i = 0, length = BROWSER_LANGUAGE_PROPERTY_KEYS_KNOWN.length; i < length; i++) {
    const language = (navigator as any)[BROWSER_LANGUAGE_PROPERTY_KEYS_KNOWN[i]];
    if (language) {
      return language.substr(0, 2);
    }
  }

  return 'en';
};
